<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ $t("pageTitle.counselingReports") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div v-if="teachers.length > 0 && (isRoleAdmin() || isRoleTeacher())">
      <h1>
        {{ `${students[0].student.first_name}'s Progress Reports` }}
        <router-link
          v-if="isRoleAdmin() || isRoleTeacher()"
          :to="{
            name: 'privateClassesCreateReport',
            params: { privateType: 'counseling', classId: $route.params.id }
          }"
        >
          <i class="el-icon-plus action-icon" />
        </router-link>
      </h1>
      <hr class="separate-line" />
      <el-alert
        v-if="
          (isRoleAdmin() || isRoleTeacher()) &&
            progressReportsCompletedCount < progressReportsNeededCount
        "
        style="margin: 20px 0px"
        show-icon
        type="warning"
      >
        <template slot="title">
          <span
            v-html="
              $t('message.teacherNeedToWriteReports', {
                howManyReportNeedToWrite: progressReportsNeededCount,
                writtenReports: progressReportsCompletedCount
              })
            "
          />
        </template>
      </el-alert>
      <el-tabs v-model="currenctReportTab">
        <template  v-if="(isRoleAdmin() || isRoleTeacher()) && !this.i_am_old_teacher">
          <el-tab-pane
            :name="String(teacher.teacher_user_id)"
            :key="teacher.teacher_user_id"
            v-for="teacher in teachers"
          >
            <template slot="label">
              <i class="fas fa-user-graduate" />
              {{ teacher.teacher_user.name }}
            </template>
            <ClassReports
              privateType="counseling"
              :className="sessionClass.title"
              :classId="$route.params.id"
              :reports="classReports[teacher.teacher_user_id]"
              @fetchReport="fetchReport"
            />
          </el-tab-pane>
        </template>
        <template
          v-if="
            (filterActive == false && (isRoleAdmin() || isRoleTeacher())) ||
              this.i_am_old_teacher
          "
        >
          <el-tab-pane
            :key="old_teacher.id"
            v-for="old_teacher in old_teachers"
            :name="String(old_teacher.id)"
          >
            <template slot="label">
              <i class="fas fa-user-graduate old-tab" />
              <span class="old-tab">{{ old_teacher.name }}</span>
            </template>
            <ClassReports
              privateType="counseling"
              :classId="$route.params.id"
              :reports="classReports[old_teacher.id]"
              @fetchReport="fetchReport"
            />
          </el-tab-pane>
        </template>
        <template>
          <el-tab-pane
            :key="student.id"
            v-for="{ student } in students"
            :label="student.name"
            :name="String(student.id)"
          >
            <template slot="label">
              <i class="far fa-user" />
              {{ student.name }}
            </template>
            <StudentReports
              privateType="counseling"
              :classId="$route.params.id"
              :reports="studentReports[student.id]"
              @fetchReport="fetchReport"
            />
          </el-tab-pane>
        </template>
        <template v-if="filterActive == false">
          <el-tab-pane
            :key="student.id"
            v-for="{ student } in old_students"
            :label="student.name"
            :name="String(student.id)"
          >
            <template slot="label">
              <i class="far fa-user old-tab" />
              <span class="old-tab">{{ student.name }}</span>
            </template>
            <StudentReports
              privateType="counseling"
              :className="sessionClass.title"
              :classId="$route.params.id"
              :reports="studentReports[student.id]"
              @fetchReport="fetchReport"
            />
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import StudentReports from "@/views/report/StudentReports";
import ClassReports from "@/views/report/ClassReports";
import privateApi from "@/apis/private";
import reportApi from "@/apis/report";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "Counseling Monthly Reports - Ivy-Way Academy"
    };
  },
  mixins: [moneyMixin, roleMixin],
  components: {
    Breadcrumb,
    IsOnlineIcon,
    StudentReports,
    ClassReports
  },
  data() {
    return {
      filterActive: true,
      subject: "",
      students: [],
      old_students: [],
      teacher: {},
      teachers: [],
      old_teachers: [],
      lessonLogs: {},
      teachingLogs: {},
      classReports: [],
      studentReports: [],
      progressReportsCompletedCount: 0,
      progressReportsNeededCount: 0,
      sessionClass: {
        course_session: {}
      },
      currenctReportTab: "teacher",
      i_am_old_teacher: 0,
      my_id: 0
    };
  },
  async created() {
    await this.initialCustomLesson();
    this.fetchReport();
  },
  methods: {
    async fetchReport() {
      const {
        class_progress_reports_group_by_user: classReports,
        student_progress_reports_group_by_user: studentReports,
        progress_reports_completed_count,
        progress_reports_needed_count
      } = await reportApi.fetchReports(this.$route.params.id);
      if (this.teacher) {
        this.classReports = classReports;
        this.studentReports = studentReports;
        this.progressReportsCompletedCount = progress_reports_completed_count;
        this.progressReportsNeededCount = progress_reports_needed_count;
      }
    },
    async initialCustomLesson() {
      const filterCanNotSeeOtherTeacher = (teachers) => {
        if (this.isRoleAdmin()) return teachers;
        const meetingTeacher = teachers.find(({ teacher_user_id }) => (
          teacher_user_id === this.$store.getters["user/getProfile"].id
        ));
        if (meetingTeacher.can_see_others) return teachers;
        return [meetingTeacher];
      };

      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.sessionClass = {
        ...customLesson.session_class
      };

      this.students = customLesson.student_class_tickets;
      this.old_students = customLesson.old_student_class_tickets;
      this.old_teachers = customLesson.old_teachers;

      this.teachers = filterCanNotSeeOtherTeacher(this.sessionClass.session_class_teachers);
      console.log(this.teachers);
      this.currenctReportTab = String(this.teachers[0].teacher_user_id);

      this.teacher = customLesson.teacher_user;
      if (this.teachers.length > 0) {
        this.teachingLogs = customLesson.teaching_logs_group_by_user;
        this.lessonLogs = customLesson.lesson_logs_group_by_user;
      }
      this.i_am_old_teacher = customLesson.i_am_old_teacher;
      this.my_id = customLesson.my_id;
      if (this.i_am_old_teacher == 1) {
        this.currenctReportTab = this.my_id;
      }
    }
  }
};
</script>
