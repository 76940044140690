var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'counselingClassesDetail',
            params: { id: _vm.$route.params.id }
          }}},[_c('IsOnlineIcon',{attrs:{"isOnline":_vm.sessionClass.course_session.is_online}}),_vm._v(" "+_vm._s(_vm.sessionClass.title)+" ")],1)],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.counselingReports"))+" ")])],1)],2),(_vm.teachers.length > 0 && (_vm.isRoleAdmin() || _vm.isRoleTeacher()))?_c('div',[_c('h1',[_vm._v(" "+_vm._s(`${_vm.students[0].student.first_name}'s Progress Reports`)+" "),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('router-link',{attrs:{"to":{
          name: 'privateClassesCreateReport',
          params: { privateType: 'counseling', classId: _vm.$route.params.id }
        }}},[_c('i',{staticClass:"el-icon-plus action-icon"})]):_vm._e()],1),_c('hr',{staticClass:"separate-line"}),(
        (_vm.isRoleAdmin() || _vm.isRoleTeacher()) &&
          _vm.progressReportsCompletedCount < _vm.progressReportsNeededCount
      )?_c('el-alert',{staticStyle:{"margin":"20px 0px"},attrs:{"show-icon":"","type":"warning"}},[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('message.teacherNeedToWriteReports', {
              howManyReportNeedToWrite: _vm.progressReportsNeededCount,
              writtenReports: _vm.progressReportsCompletedCount
            })
          )}})])],2):_vm._e(),_c('el-tabs',{model:{value:(_vm.currenctReportTab),callback:function ($$v) {_vm.currenctReportTab=$$v},expression:"currenctReportTab"}},[((_vm.isRoleAdmin() || _vm.isRoleTeacher()) && !this.i_am_old_teacher)?_vm._l((_vm.teachers),function(teacher){return _c('el-tab-pane',{key:teacher.teacher_user_id,attrs:{"name":String(teacher.teacher_user_id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"fas fa-user-graduate"}),_vm._v(" "+_vm._s(teacher.teacher_user.name)+" ")]),_c('ClassReports',{attrs:{"privateType":"counseling","className":_vm.sessionClass.title,"classId":_vm.$route.params.id,"reports":_vm.classReports[teacher.teacher_user_id]},on:{"fetchReport":_vm.fetchReport}})],2)}):_vm._e(),(
          (_vm.filterActive == false && (_vm.isRoleAdmin() || _vm.isRoleTeacher())) ||
            this.i_am_old_teacher
        )?_vm._l((_vm.old_teachers),function(old_teacher){return _c('el-tab-pane',{key:old_teacher.id,attrs:{"name":String(old_teacher.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"fas fa-user-graduate old-tab"}),_c('span',{staticClass:"old-tab"},[_vm._v(_vm._s(old_teacher.name))])]),_c('ClassReports',{attrs:{"privateType":"counseling","classId":_vm.$route.params.id,"reports":_vm.classReports[old_teacher.id]},on:{"fetchReport":_vm.fetchReport}})],2)}):_vm._e(),_vm._l((_vm.students),function({ student }){return _c('el-tab-pane',{key:student.id,attrs:{"label":student.name,"name":String(student.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(student.name)+" ")]),_c('StudentReports',{attrs:{"privateType":"counseling","classId":_vm.$route.params.id,"reports":_vm.studentReports[student.id]},on:{"fetchReport":_vm.fetchReport}})],2)}),(_vm.filterActive == false)?_vm._l((_vm.old_students),function({ student }){return _c('el-tab-pane',{key:student.id,attrs:{"label":student.name,"name":String(student.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"far fa-user old-tab"}),_c('span',{staticClass:"old-tab"},[_vm._v(_vm._s(student.name))])]),_c('StudentReports',{attrs:{"privateType":"counseling","className":_vm.sessionClass.title,"classId":_vm.$route.params.id,"reports":_vm.studentReports[student.id]},on:{"fetchReport":_vm.fetchReport}})],2)}):_vm._e()],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }